/*------ Icons List ------*/

.browser {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  background: no-repeat center/100% 100%;
  vertical-align: bottom;
  font-style: normal;
}

.browser-android-browser {
  background-image: url("../images/browsers/android-browser.svg");
}

.browser-aol-explorer {
  background-image: url("../images/browsers/aol-explorer.svg");
}

.browser-blackberry {
  background-image: url("../images/browsers/blackberry.svg");
}

.browser-camino {
  background-image: url("../images/browsers/camino.svg");
}

.browser-chrome {
  background-image: url("../images/browsers/chrome.svg");
}

.browser-chromium {
  background-image: url("../images/browsers/chromium.svg");
}

.browser-dolphin {
  background-image: url("../images/browsers/dolphin.svg");
}

.browser-edge {
  background-image: url("../images/browsers/edge.svg");
}

.browser-firefox {
  background-image: url("../images/browsers/firefox.svg");
}

.browser-ie {
  background-image: url("../images/browsers/ie.svg");
}

.browser-maxthon {
  background-image: url("../images/browsers/maxthon.svg");
}

.browser-mozilla {
  background-image: url("../images/browsers/mozilla.svg");
}

.browser-netscape {
  background-image: url("../images/browsers/netscape.svg");
}

.browser-opera {
  background-image: url("../images/browsers/opera.svg");
}

.browser-safari {
  background-image: url("../images/browsers/safari.svg");
}

.browser-sleipnir {
  background-image: url("../images/browsers/sleipnir.svg");
}

.browser-uc-browser {
  background-image: url("../images/browsers/uc-browser.svg");
}

.browser-vivaldi {
  background-image: url("../images/browsers/vivaldi.svg");
}

.flag {
  width: 1.6rem;
  height: 1.2rem;
  display: inline-block;
  background: no-repeat center/100% 100%;
  vertical-align: bottom;
  font-style: normal;
  box-shadow: 0 0 1px 1px $black-1;
  border-radius: 2px;
}

.flag-ad {
  background-image: url("../images/flags/ad.svg");
}

.flag-ae {
  background-image: url("../images/flags/ae.svg");
}

.flag-af {
  background-image: url("../images/flags/af.svg");
}

.flag-ag {
  background-image: url("../images/flags/ag.svg");
}

.flag-ai {
  background-image: url("../images/flags/ai.svg");
}

.flag-al {
  background-image: url("../images/flags/al.svg");
}

.flag-am {
  background-image: url("../images/flags/am.svg");
}

.flag-ao {
  background-image: url("../images/flags/ao.svg");
}

.flag-aq {
  background-image: url("../images/flags/aq.svg");
}

.flag-ar {
  background-image: url("../images/flags/ar.svg");
}

.flag-as {
  background-image: url("../images/flags/as.svg");
}

.flag-at {
  background-image: url("../images/flags/at.svg");
}

.flag-au {
  background-image: url("../images/flags/au.svg");
}

.flag-aw {
  background-image: url("../images/flags/aw.svg");
}

.flag-ax {
  background-image: url("../images/flags/ax.svg");
}

.flag-az {
  background-image: url("../images/flags/az.svg");
}

.flag-ba {
  background-image: url("../images/flags/ba.svg");
}

.flag-bb {
  background-image: url("../images/flags/bb.svg");
}

.flag-bd {
  background-image: url("../images/flags/bd.svg");
}

.flag-be {
  background-image: url("../images/flags/be.svg");
}

.flag-bf {
  background-image: url("../images/flags/bf.svg");
}

.flag-bg {
  background-image: url("../images/flags/bg.svg");
}

.flag-bh {
  background-image: url("../images/flags/bh.svg");
}

.flag-bi {
  background-image: url("../images/flags/bi.svg");
}

.flag-bj {
  background-image: url("../images/flags/bj.svg");
}

.flag-bl {
  background-image: url("../images/flags/bl.svg");
}

.flag-bm {
  background-image: url("../images/flags/bm.svg");
}

.flag-bn {
  background-image: url("../images/flags/bn.svg");
}

.flag-bo {
  background-image: url("../images/flags/bo.svg");
}

.flag-bq {
  background-image: url("../images/flags/bq.svg");
}

.flag-br {
  background-image: url("../images/flags/br.svg");
}

.flag-bs {
  background-image: url("../images/flags/bs.svg");
}

.flag-bt {
  background-image: url("../images/flags/bt.svg");
}

.flag-bv {
  background-image: url("../images/flags/bv.svg");
}

.flag-bw {
  background-image: url("../images/flags/bw.svg");
}

.flag-by {
  background-image: url("../images/flags/by.svg");
}

.flag-bz {
  background-image: url("../images/flags/bz.svg");
}

.flag-ca {
  background-image: url("../images/flags/ca.svg");
}

.flag-cc {
  background-image: url("../images/flags/cc.svg");
}

.flag-cd {
  background-image: url("../images/flags/cd.svg");
}

.flag-cf {
  background-image: url("../images/flags/cf.svg");
}

.flag-cg {
  background-image: url("../images/flags/cg.svg");
}

.flag-ch {
  background-image: url("../images/flags/ch.svg");
}

.flag-ci {
  background-image: url("../images/flags/ci.svg");
}

.flag-ck {
  background-image: url("../images/flags/ck.svg");
}

.flag-cl {
  background-image: url("../images/flags/cl.svg");
}

.flag-cm {
  background-image: url("../images/flags/cm.svg");
}

.flag-cn {
  background-image: url("../images/flags/cn.svg");
}

.flag-co {
  background-image: url("../images/flags/co.svg");
}

.flag-cr {
  background-image: url("../images/flags/cr.svg");
}

.flag-cu {
  background-image: url("../images/flags/cu.svg");
}

.flag-cv {
  background-image: url("../images/flags/cv.svg");
}

.flag-cw {
  background-image: url("../images/flags/cw.svg");
}

.flag-cx {
  background-image: url("../images/flags/cx.svg");
}

.flag-cy {
  background-image: url("../images/flags/cy.svg");
}

.flag-cz {
  background-image: url("../images/flags/cz.svg");
}

.flag-de {
  background-image: url("../images/flags/de.svg");
}

.flag-dj {
  background-image: url("../images/flags/dj.svg");
}

.flag-dk {
  background-image: url("../images/flags/dk.svg");
}

.flag-dm {
  background-image: url("../images/flags/dm.svg");
}

.flag-do {
  background-image: url("../images/flags/do.svg");
}

.flag-dz {
  background-image: url("../images/flags/dz.svg");
}

.flag-ec {
  background-image: url("../images/flags/ec.svg");
}

.flag-ee {
  background-image: url("../images/flags/ee.svg");
}

.flag-eg {
  background-image: url("../images/flags/eg.svg");
}

.flag-eh {
  background-image: url("../images/flags/eh.svg");
}

.flag-er {
  background-image: url("../images/flags/er.svg");
}

.flag-es {
  background-image: url("../images/flags/es.svg");
}

.flag-et {
  background-image: url("../images/flags/et.svg");
}

.flag-eu {
  background-image: url("../images/flags/eu.svg");
}

.flag-fi {
  background-image: url("../images/flags/fi.svg");
}

.flag-fj {
  background-image: url("../images/flags/fj.svg");
}

.flag-fk {
  background-image: url("../images/flags/fk.svg");
}

.flag-fm {
  background-image: url("../images/flags/fm.svg");
}

.flag-fo {
  background-image: url("../images/flags/fo.svg");
}

.flag-fr {
  background-image: url("../images/flags/fr.svg");
}

.flag-ga {
  background-image: url("../images/flags/ga.svg");
}

.flag-gb-eng {
  background-image: url("../images/flags/gb-eng.svg");
}

.flag-gb-nir {
  background-image: url("../images/flags/gb-nir.svg");
}

.flag-gb-sct {
  background-image: url("../images/flags/gb-sct.svg");
}

.flag-gb-wls {
  background-image: url("../images/flags/gb-wls.svg");
}

.flag-gb {
  background-image: url("../images/flags/gb.svg");
}

.flag-gd {
  background-image: url("../images/flags/gd.svg");
}

.flag-ge {
  background-image: url("../images/flags/ge.svg");
}

.flag-gf {
  background-image: url("../images/flags/gf.svg");
}

.flag-gg {
  background-image: url("../images/flags/gg.svg");
}

.flag-gh {
  background-image: url("../images/flags/gh.svg");
}

.flag-gi {
  background-image: url("../images/flags/gi.svg");
}

.flag-gl {
  background-image: url("../images/flags/gl.svg");
}

.flag-gm {
  background-image: url("../images/flags/gm.svg");
}

.flag-gn {
  background-image: url("../images/flags/gn.svg");
}

.flag-gp {
  background-image: url("../images/flags/gp.svg");
}

.flag-gq {
  background-image: url("../images/flags/gq.svg");
}

.flag-gr {
  background-image: url("../images/flags/gr.svg");
}

.flag-gs {
  background-image: url("../images/flags/gs.svg");
}

.flag-gt {
  background-image: url("../images/flags/gt.svg");
}

.flag-gu {
  background-image: url("../images/flags/gu.svg");
}

.flag-gw {
  background-image: url("../images/flags/gw.svg");
}

.flag-gy {
  background-image: url("../images/flags/gy.svg");
}

.flag-hk {
  background-image: url("../images/flags/hk.svg");
}

.flag-hm {
  background-image: url("../images/flags/hm.svg");
}

.flag-hn {
  background-image: url("../images/flags/hn.svg");
}

.flag-hr {
  background-image: url("../images/flags/hr.svg");
}

.flag-ht {
  background-image: url("../images/flags/ht.svg");
}

.flag-hu {
  background-image: url("../images/flags/hu.svg");
}

.flag-id {
  background-image: url("../images/flags/id.svg");
}

.flag-ie {
  background-image: url("../images/flags/ie.svg");
}

.flag-il {
  background-image: url("../images/flags/il.svg");
}

.flag-im {
  background-image: url("../images/flags/im.svg");
}

.flag-in {
  background-image: url("../images/flags/in.svg");
}

.flag-io {
  background-image: url("../images/flags/io.svg");
}

.flag-iq {
  background-image: url("../images/flags/iq.svg");
}

.flag-ir {
  background-image: url("../images/flags/ir.svg");
}

.flag-is {
  background-image: url("../images/flags/is.svg");
}

.flag-it {
  background-image: url("../images/flags/it.svg");
}

.flag-je {
  background-image: url("../images/flags/je.svg");
}

.flag-jm {
  background-image: url("../images/flags/jm.svg");
}

.flag-jo {
  background-image: url("../images/flags/jo.svg");
}

.flag-jp {
  background-image: url("../images/flags/jp.svg");
}

.flag-ke {
  background-image: url("../images/flags/ke.svg");
}

.flag-kg {
  background-image: url("../images/flags/kg.svg");
}

.flag-kh {
  background-image: url("../images/flags/kh.svg");
}

.flag-ki {
  background-image: url("../images/flags/ki.svg");
}

.flag-km {
  background-image: url("../images/flags/km.svg");
}

.flag-kn {
  background-image: url("../images/flags/kn.svg");
}

.flag-kp {
  background-image: url("../images/flags/kp.svg");
}

.flag-kr {
  background-image: url("../images/flags/kr.svg");
}

.flag-kw {
  background-image: url("../images/flags/kw.svg");
}

.flag-ky {
  background-image: url("../images/flags/ky.svg");
}

.flag-kz {
  background-image: url("../images/flags/kz.svg");
}

.flag-la {
  background-image: url("../images/flags/la.svg");
}

.flag-lb {
  background-image: url("../images/flags/lb.svg");
}

.flag-lc {
  background-image: url("../images/flags/lc.svg");
}

.flag-li {
  background-image: url("../images/flags/li.svg");
}

.flag-lk {
  background-image: url("../images/flags/lk.svg");
}

.flag-lr {
  background-image: url("../images/flags/lr.svg");
}

.flag-ls {
  background-image: url("../images/flags/ls.svg");
}

.flag-lt {
  background-image: url("../images/flags/lt.svg");
}

.flag-lu {
  background-image: url("../images/flags/lu.svg");
}

.flag-lv {
  background-image: url("../images/flags/lv.svg");
}

.flag-ly {
  background-image: url("../images/flags/ly.svg");
}

.flag-ma {
  background-image: url("../images/flags/ma.svg");
}

.flag-mc {
  background-image: url("../images/flags/mc.svg");
}

.flag-md {
  background-image: url("../images/flags/md.svg");
}

.flag-me {
  background-image: url("../images/flags/me.svg");
}

.flag-mf {
  background-image: url("../images/flags/mf.svg");
}

.flag-mg {
  background-image: url("../images/flags/mg.svg");
}

.flag-mh {
  background-image: url("../images/flags/mh.svg");
}

.flag-mk {
  background-image: url("../images/flags/mk.svg");
}

.flag-ml {
  background-image: url("../images/flags/ml.svg");
}

.flag-mm {
  background-image: url("../images/flags/mm.svg");
}

.flag-mn {
  background-image: url("../images/flags/mn.svg");
}

.flag-mo {
  background-image: url("../images/flags/mo.svg");
}

.flag-mp {
  background-image: url("../images/flags/mp.svg");
}

.flag-mq {
  background-image: url("../images/flags/mq.svg");
}

.flag-mr {
  background-image: url("../images/flags/mr.svg");
}

.flag-ms {
  background-image: url("../images/flags/ms.svg");
}

.flag-mt {
  background-image: url("../images/flags/mt.svg");
}

.flag-mu {
  background-image: url("../images/flags/mu.svg");
}

.flag-mv {
  background-image: url("../images/flags/mv.svg");
}

.flag-mw {
  background-image: url("../images/flags/mw.svg");
}

.flag-mx {
  background-image: url("../images/flags/mx.svg");
}

.flag-my {
  background-image: url("../images/flags/my.svg");
}

.flag-mz {
  background-image: url("../images/flags/mz.svg");
}

.flag-na {
  background-image: url("../images/flags/na.svg");
}

.flag-nc {
  background-image: url("../images/flags/nc.svg");
}

.flag-ne {
  background-image: url("../images/flags/ne.svg");
}

.flag-nf {
  background-image: url("../images/flags/nf.svg");
}

.flag-ng {
  background-image: url("../images/flags/ng.svg");
}

.flag-ni {
  background-image: url("../images/flags/ni.svg");
}

.flag-nl {
  background-image: url("../images/flags/nl.svg");
}

.flag-no {
  background-image: url("../images/flags/no.svg");
}

.flag-np {
  background-image: url("../images/flags/np.svg");
}

.flag-nr {
  background-image: url("../images/flags/nr.svg");
}

.flag-nu {
  background-image: url("../images/flags/nu.svg");
}

.flag-nz {
  background-image: url("../images/flags/nz.svg");
}

.flag-om {
  background-image: url("../images/flags/om.svg");
}

.flag-pa {
  background-image: url("../images/flags/pa.svg");
}

.flag-pe {
  background-image: url("../images/flags/pe.svg");
}

.flag-pf {
  background-image: url("../images/flags/pf.svg");
}

.flag-pg {
  background-image: url("../images/flags/pg.svg");
}

.flag-ph {
  background-image: url("../images/flags/ph.svg");
}

.flag-pk {
  background-image: url("../images/flags/pk.svg");
}

.flag-pl {
  background-image: url("../images/flags/pl.svg");
}

.flag-pm {
  background-image: url("../images/flags/pm.svg");
}

.flag-pn {
  background-image: url("../images/flags/pn.svg");
}

.flag-pr {
  background-image: url("../images/flags/pr.svg");
}

.flag-ps {
  background-image: url("../images/flags/ps.svg");
}

.flag-pt {
  background-image: url("../images/flags/pt.svg");
}

.flag-pw {
  background-image: url("../images/flags/pw.svg");
}

.flag-py {
  background-image: url("../images/flags/py.svg");
}

.flag-qa {
  background-image: url("../images/flags/qa.svg");
}

.flag-re {
  background-image: url("../images/flags/re.svg");
}

.flag-ro {
  background-image: url("../images/flags/ro.svg");
}

.flag-rs {
  background-image: url("../images/flags/rs.svg");
}

.flag-ru {
  background-image: url("../images/flags/ru.svg");
}

.flag-rw {
  background-image: url("../images/flags/rw.svg");
}

.flag-sa {
  background-image: url("../images/flags/sa.svg");
}

.flag-sb {
  background-image: url("../images/flags/sb.svg");
}

.flag-sc {
  background-image: url("../images/flags/sc.svg");
}

.flag-sd {
  background-image: url("../images/flags/sd.svg");
}

.flag-se {
  background-image: url("../images/flags/se.svg");
}

.flag-sg {
  background-image: url("../images/flags/sg.svg");
}

.flag-sh {
  background-image: url("../images/flags/sh.svg");
}

.flag-si {
  background-image: url("../images/flags/si.svg");
}

.flag-sj {
  background-image: url("../images/flags/sj.svg");
}

.flag-sk {
  background-image: url("../images/flags/sk.svg");
}

.flag-sl {
  background-image: url("../images/flags/sl.svg");
}

.flag-sm {
  background-image: url("../images/flags/sm.svg");
}

.flag-sn {
  background-image: url("../images/flags/sn.svg");
}

.flag-so {
  background-image: url("../images/flags/so.svg");
}

.flag-sr {
  background-image: url("../images/flags/sr.svg");
}

.flag-ss {
  background-image: url("../images/flags/ss.svg");
}

.flag-st {
  background-image: url("../images/flags/st.svg");
}

.flag-sv {
  background-image: url("../images/flags/sv.svg");
}

.flag-sx {
  background-image: url("../images/flags/sx.svg");
}

.flag-sy {
  background-image: url("../images/flags/sy.svg");
}

.flag-sz {
  background-image: url("../images/flags/sz.svg");
}

.flag-tc {
  background-image: url("../images/flags/tc.svg");
}

.flag-td {
  background-image: url("../images/flags/td.svg");
}

.flag-tf {
  background-image: url("../images/flags/tf.svg");
}

.flag-tg {
  background-image: url("../images/flags/tg.svg");
}

.flag-th {
  background-image: url("../images/flags/th.svg");
}

.flag-tj {
  background-image: url("../images/flags/tj.svg");
}

.flag-tk {
  background-image: url("../images/flags/tk.svg");
}

.flag-tl {
  background-image: url("../images/flags/tl.svg");
}

.flag-tm {
  background-image: url("../images/flags/tm.svg");
}

.flag-tn {
  background-image: url("../images/flags/tn.svg");
}

.flag-to {
  background-image: url("../images/flags/to.svg");
}

.flag-tr {
  background-image: url("../images/flags/tr.svg");
}

.flag-tt {
  background-image: url("../images/flags/tt.svg");
}

.flag-tv {
  background-image: url("../images/flags/tv.svg");
}

.flag-tw {
  background-image: url("../images/flags/tw.svg");
}

.flag-tz {
  background-image: url("../images/flags/tz.svg");
}

.flag-ua {
  background-image: url("../images/flags/ua.svg");
}

.flag-ug {
  background-image: url("../images/flags/ug.svg");
}

.flag-um {
  background-image: url("../images/flags/um.svg");
}

.flag-un {
  background-image: url("../images/flags/un.svg");
}

.flag-us {
  background-image: url("../images/flags/us.svg");
}

.flag-uy {
  background-image: url("../images/flags/uy.svg");
}

.flag-uz {
  background-image: url("../images/flags/uz.svg");
}

.flag-va {
  background-image: url("../images/flags/va.svg");
}

.flag-vc {
  background-image: url("../images/flags/vc.svg");
}

.flag-ve {
  background-image: url("../images/flags/ve.svg");
}

.flag-vg {
  background-image: url("../images/flags/vg.svg");
}

.flag-vi {
  background-image: url("../images/flags/vi.svg");
}

.flag-vn {
  background-image: url("../images/flags/vn.svg");
}

.flag-vu {
  background-image: url("../images/flags/vu.svg");
}

.flag-wf {
  background-image: url("../images/flags/wf.svg");
}

.flag-ws {
  background-image: url("../images/flags/ws.svg");
}

.flag-ye {
  background-image: url("../images/flags/ye.svg");
}

.flag-yt {
  background-image: url("../images/flags/yt.svg");
}

.flag-za {
  background-image: url("../images/flags/za.svg");
}

.flag-zm {
  background-image: url("../images/flags/zm.svg");
}

.flag-zw {
  background-image: url("../images/flags/zw.svg");
}

.payment {
  width: 2.5rem;
  height: 1.5rem;
  display: inline-block;
  background: no-repeat center/100% 100%;
  vertical-align: bottom;
  font-style: normal;
  box-shadow: 0 0 1px 1px $black-1;
  border-radius: 2px;
}

.payment-2checkout-dark {
  background-image: url("../images/payments/2checkout-dark.svg");
}

.payment-2checkout {
  background-image: url("../images/payments/2checkout.svg");
}

.payment-alipay-dark {
  background-image: url("../images/payments/alipay-dark.svg");
}

.payment-alipay {
  background-image: url("../images/payments/alipay.svg");
}

.payment-amazon-dark {
  background-image: url("../images/payments/amazon-dark.svg");
}

.payment-amazon {
  background-image: url("../images/payments/amazon.svg");
}

.payment-americanexpress-dark {
  background-image: url("../images/payments/americanexpress-dark.svg");
}

.payment-americanexpress {
  background-image: url("../images/payments/americanexpress.svg");
}

.payment-applepay-dark {
  background-image: url("../images/payments/applepay-dark.svg");
}

.payment-applepay {
  background-image: url("../images/payments/applepay.svg");
}

.payment-bancontact-dark {
  background-image: url("../images/payments/bancontact-dark.svg");
}

.payment-bancontact {
  background-image: url("../images/payments/bancontact.svg");
}

.payment-bitcoin-dark {
  background-image: url("../images/payments/bitcoin-dark.svg");
}

.payment-bitcoin {
  background-image: url("../images/payments/bitcoin.svg");
}

.payment-bitpay-dark {
  background-image: url("../images/payments/bitpay-dark.svg");
}

.payment-bitpay {
  background-image: url("../images/payments/bitpay.svg");
}

.payment-cirrus-dark {
  background-image: url("../images/payments/cirrus-dark.svg");
}

.payment-cirrus {
  background-image: url("../images/payments/cirrus.svg");
}

.payment-clickandbuy-dark {
  background-image: url("../images/payments/clickandbuy-dark.svg");
}

.payment-clickandbuy {
  background-image: url("../images/payments/clickandbuy.svg");
}

.payment-coinkite-dark {
  background-image: url("../images/payments/coinkite-dark.svg");
}

.payment-coinkite {
  background-image: url("../images/payments/coinkite.svg");
}

.payment-dinersclub-dark {
  background-image: url("../images/payments/dinersclub-dark.svg");
}

.payment-dinersclub {
  background-image: url("../images/payments/dinersclub.svg");
}

.payment-directdebit-dark {
  background-image: url("../images/payments/directdebit-dark.svg");
}

.payment-directdebit {
  background-image: url("../images/payments/directdebit.svg");
}

.payment-discover-dark {
  background-image: url("../images/payments/discover-dark.svg");
}

.payment-discover {
  background-image: url("../images/payments/discover.svg");
}

.payment-dwolla-dark {
  background-image: url("../images/payments/dwolla-dark.svg");
}

.payment-dwolla {
  background-image: url("../images/payments/dwolla.svg");
}

.payment-ebay-dark {
  background-image: url("../images/payments/ebay-dark.svg");
}

.payment-ebay {
  background-image: url("../images/payments/ebay.svg");
}

.payment-eway-dark {
  background-image: url("../images/payments/eway-dark.svg");
}

.payment-eway {
  background-image: url("../images/payments/eway.svg");
}

.payment-giropay-dark {
  background-image: url("../images/payments/giropay-dark.svg");
}

.payment-giropay {
  background-image: url("../images/payments/giropay.svg");
}

.payment-googlewallet-dark {
  background-image: url("../images/payments/googlewallet-dark.svg");
}

.payment-googlewallet {
  background-image: url("../images/payments/googlewallet.svg");
}

.payment-ingenico-dark {
  background-image: url("../images/payments/ingenico-dark.svg");
}

.payment-ingenico {
  background-image: url("../images/payments/ingenico.svg");
}

.payment-jcb-dark {
  background-image: url("../images/payments/jcb-dark.svg");
}

.payment-jcb {
  background-image: url("../images/payments/jcb.svg");
}

.payment-klarna-dark {
  background-image: url("../images/payments/klarna-dark.svg");
}

.payment-klarna {
  background-image: url("../images/payments/klarna.svg");
}

.payment-laser-dark {
  background-image: url("../images/payments/laser-dark.svg");
}

.payment-laser {
  background-image: url("../images/payments/laser.svg");
}

.payment-maestro-dark {
  background-image: url("../images/payments/maestro-dark.svg");
}

.payment-maestro {
  background-image: url("../images/payments/maestro.svg");
}

.payment-mastercard-dark {
  background-image: url("../images/payments/mastercard-dark.svg");
}

.payment-mastercard {
  background-image: url("../images/payments/mastercard.svg");
}

.payment-monero-dark {
  background-image: url("../images/payments/monero-dark.svg");
}

.payment-monero {
  background-image: url("../images/payments/monero.svg");
}

.payment-neteller-dark {
  background-image: url("../images/payments/neteller-dark.svg");
}

.payment-neteller {
  background-image: url("../images/payments/neteller.svg");
}

.payment-ogone-dark {
  background-image: url("../images/payments/ogone-dark.svg");
}

.payment-ogone {
  background-image: url("../images/payments/ogone.svg");
}

.payment-okpay-dark {
  background-image: url("../images/payments/okpay-dark.svg");
}

.payment-okpay {
  background-image: url("../images/payments/okpay.svg");
}

.payment-paybox-dark {
  background-image: url("../images/payments/paybox-dark.svg");
}

.payment-paybox {
  background-image: url("../images/payments/paybox.svg");
}

.payment-paymill-dark {
  background-image: url("../images/payments/paymill-dark.svg");
}

.payment-paymill {
  background-image: url("../images/payments/paymill.svg");
}

.payment-payone-dark {
  background-image: url("../images/payments/payone-dark.svg");
}

.payment-payone {
  background-image: url("../images/payments/payone.svg");
}

.payment-payoneer-dark {
  background-image: url("../images/payments/payoneer-dark.svg");
}

.payment-payoneer {
  background-image: url("../images/payments/payoneer.svg");
}

.payment-paypal-dark {
  background-image: url("../images/payments/paypal-dark.svg");
}

.payment-paypal {
  background-image: url("../images/payments/paypal.svg");
}

.payment-paysafecard-dark {
  background-image: url("../images/payments/paysafecard-dark.svg");
}

.payment-paysafecard {
  background-image: url("../images/payments/paysafecard.svg");
}

.payment-payu-dark {
  background-image: url("../images/payments/payu-dark.svg");
}

.payment-payu {
  background-image: url("../images/payments/payu.svg");
}

.payment-payza-dark {
  background-image: url("../images/payments/payza-dark.svg");
}

.payment-payza {
  background-image: url("../images/payments/payza.svg");
}

.payment-ripple-dark {
  background-image: url("../images/payments/ripple-dark.svg");
}

.payment-ripple {
  background-image: url("../images/payments/ripple.svg");
}

.payment-sage-dark {
  background-image: url("../images/payments/sage-dark.svg");
}

.payment-sage {
  background-image: url("../images/payments/sage.svg");
}

.payment-sepa-dark {
  background-image: url("../images/payments/sepa-dark.svg");
}

.payment-sepa {
  background-image: url("../images/payments/sepa.svg");
}

.payment-shopify-dark {
  background-image: url("../images/payments/shopify-dark.svg");
}

.payment-shopify {
  background-image: url("../images/payments/shopify.svg");
}

.payment-skrill-dark {
  background-image: url("../images/payments/skrill-dark.svg");
}

.payment-skrill {
  background-image: url("../images/payments/skrill.svg");
}

.payment-solo-dark {
  background-image: url("../images/payments/solo-dark.svg");
}

.payment-solo {
  background-image: url("../images/payments/solo.svg");
}

.payment-square-dark {
  background-image: url("../images/payments/square-dark.svg");
}

.payment-square {
  background-image: url("../images/payments/square.svg");
}

.payment-stripe-dark {
  background-image: url("../images/payments/stripe-dark.svg");
}

.payment-stripe {
  background-image: url("../images/payments/stripe.svg");
}

.payment-switch-dark {
  background-image: url("../images/payments/switch-dark.svg");
}

.payment-switch {
  background-image: url("../images/payments/switch.svg");
}

.payment-ukash-dark {
  background-image: url("../images/payments/ukash-dark.svg");
}

.payment-ukash {
  background-image: url("../images/payments/ukash.svg");
}

.payment-unionpay-dark {
  background-image: url("../images/payments/unionpay-dark.svg");
}

.payment-unionpay {
  background-image: url("../images/payments/unionpay.svg");
}

.payment-verifone-dark {
  background-image: url("../images/payments/verifone-dark.svg");
}

.payment-verifone {
  background-image: url("../images/payments/verifone.svg");
}

.payment-verisign-dark {
  background-image: url("../images/payments/verisign-dark.svg");
}

.payment-verisign {
  background-image: url("../images/payments/verisign.svg");
}

.payment-visa-dark {
  background-image: url("../images/payments/visa-dark.svg");
}

.payment-visa {
  background-image: url("../images/payments/visa.svg");
}

.payment-webmoney-dark {
  background-image: url("../images/payments/webmoney-dark.svg");
}

.payment-webmoney {
  background-image: url("../images/payments/webmoney.svg");
}

.payment-westernunion-dark {
  background-image: url("../images/payments/westernunion-dark.svg");
}

.payment-westernunion {
  background-image: url("../images/payments/westernunion.svg");
}

.payment-worldpay-dark {
  background-image: url("../images/payments/worldpay-dark.svg");
}

.payment-worldpay {
  background-image: url("../images/payments/worldpay.svg");
}

svg {
  -ms-touch-action: none;
  touch-action: none;
}