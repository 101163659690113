@media (max-width: 991.98px) {
	.main-content-body-show {
	  .main-header-menu-icon {
		display: none;
	  }
  
	  .main-header-left .main-header-arrow {
		display: block;
	  }
  
	  .main-content-left-chat {
		display: none;
	  }
  
	  .main-content-body-chat {
		display: flex;
	  }
	}
  }
  
  .main-content-left-chat {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 0;
  }
  
  @media (min-width: 992px) {
	.main-content-left-chat {
	  width: auto;
	  border: 0;
	  border-left: 0;
	}
  }
  
  .main-nav-line-chat {
	border-bottom: 1px solid #e9edf4;
	flex-shrink: 0;
	flex-direction: row;
	padding: 10px;
  
	.nav-link {
	  font-size: 13px;
	  font-weight: 500;
	  text-transform: uppercase;
	  padding: 6px 15px;
	  border-radius: 3px;
  
	  &:hover, &:focus, &:active {
		outline: none;
	  }
  
	  margin-top: 0;
	  margin-left: 10px;
	  background: #e9edf4;
  
	  &.active {
		color: $white;
		background: $primary;
  
		&::before {
		  top: auto;
		  left: 0;
		  right: 0;
		  width: auto;
		  bottom: -11px;
		  background-color: #4c84ff;
		}
	  }
	}
  }
  
  @media (min-width: 992px) {
	.main-nav-line-chat {
	  padding-right: 0;
	}
  }
  
  .main-chat-contacts-wrapper {
	padding: 10px 20px;
	flex-shrink: 0;
	border-bottom: 1px solid #e9edf4;
	overflow: hidden;
  
	.main-content-label, .card-table-two .card-title {
	  margin-bottom: 15px;
	  line-height: 1;
	}
  }
  
  @media (min-width: 992px) {
	.main-chat-contacts-wrapper {
	  padding: 10px 0px 10px 15px;
	}
  }
  
  .card-table-two .main-chat-contacts-wrapper .card-title, .main-chat-contacts-wrapper .card--eight .card-title, .card--eight .main-chat-contacts-wrapper .card-title {
	margin-bottom: 15px;
	line-height: 1;
  }
  
  .main-chat-contacts-wrapper {
	.lSSlideOuter, .lSSlideWrapper {
	  overflow: visible;
	}
  }
  
  .main-chat-contacts {
	padding-left: 20px;
	display: flex;
  
	.lslide {
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  float: none;
	}
  
	small {
	  font-size: 11px;
	  width: 36px;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	  overflow: hidden;
	  margin-top: 3px;
	  text-align: center;
	}
  }
  
  .main-chat-contacts-more {
	width: 36px;
	height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	background-color: #4c84ff;
	font-size: 12px;
	font-weight: 500;
	color: $white;
  }
  
  .main-chat-list {
	max-height: 500px;
	position: relative;
  
	.media {
	  padding: 12px 20px;
  
	  /* border: 1px solid #e9edf4; */
	  position: relative;
  
	  .main-img-user {
		flex-shrink: 0;
		top: 3px;
		position: relative;
	  }
  
	  border-bottom: 1px solid #eff0f6;
  
	  &:last-chid {
		border-bottom: 0;
	  }
  
	  + .media::before {
		content: '';
		position: absolute;
		top: -1px;
		left: 0;
		right: 0;
		border-top: 1px solid #e9edf4;
	  }
  
	  &.new {
		/* background-color: $white; */
  
		.main-img-user span {
		  display: flex;
		}
  
		.media-contact-name span:first-child {
		  font-weight: 700;
		  color: #14112d;
		}
  
		.media-body p {
		  color: #22252f;
		}
	  }
  
	  &:hover, &:focus {
		cursor: pointer;
		background-color: #f3f4f7;
		border-top-color: #eff0f6;
		border-bottom-color: #eff0f6;
	  }
  
	  &:hover:first-child, &:focus:first-child {
		/* border-top-color: transparent; */
	  }
  
	  &.selected {
		background-color: #f3f4f7;
		border-top-color: #eff0f6;
		border-bottom-color: #eff0f6;
  
		&:first-child {
		  border-top-color: transparent;
		}
  
		&::after {
		  display: block;
		}
  
		.media-contact-name span:first-child {
		  color: #14112d;
		}
  
		.media-body p {
		  color: #22252f;
		}
	  }
	}
  
	.main-img-user span {
	  position: absolute;
	  top: 0;
	  left: -2px;
	  width: 14px;
	  height: 14px;
	  display: none;
	  align-items: center;
	  justify-content: center;
	  border-radius: 100%;
	  font-size: 9px;
	  font-weight: 500;
	  color: $white;
	  background-color: #ff5c77;
	  box-shadow: 0 0 0 2px $white;
	}
  
	.media-body {
	  margin-left: 15px;
  
	  p {
		font-size: 13px;
		margin-bottom: 0;
		color: #737688;
		line-height: 1.35;
	  }
	}
  
	.media-contact-name {
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
	  margin-bottom: 2px;
  
	  span {
		&:first-child {
		  display: block;
		  font-size: 14px;
		  font-weight: 500;
		  color: #14112d;
		}
  
		&:last-child {
		  display: block;
		  font-size: 11px;
		  color: #737688;
		}
	  }
	}
  }
  
  @media (min-width: 992px) {
	.main-chat-list .media {
	  padding: 12px 15px;
	  margin: 0;
	}
  }
  
  .main-content-body-chat {
	flex-direction: column;
  }
  
  @media (min-width: 992px) {
	.main-content-body-chat {
	  display: flex;
	}
  }
  
  .main-chat-header {
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 12px 20px 12px;
	border-bottom: 1px solid #e9edf4;
  
	.nav {
	  margin-left: auto;
	  align-items: center;
	}
  
	.nav-link {
	  padding: 0;
	  color: #737688;
	  font-size: 20px;
	  display: none;
	  margin-top: 8px;
  
	  &:first-child {
		display: block;
	  }
  
	  i {
		line-height: 0;
	  }
  
	  .typcn {
		line-height: .9;
  
		&::before {
		  width: auto;
		}
	  }
  
	  &:hover, &:focus {
		color: #4c84ff;
	  }
  
	  + .nav-link {
		margin-left: 10px;
	  }
	}
  }
  
  @media (min-width: 576px) {
	.main-chat-header .nav-link {
	  display: block;
  
	  &:first-child {
		display: none;
	  }
	}
  }
  
  .main-chat-msg-name {
	margin-left: 15px;
  
	h6 {
	  margin-bottom: 2px;
	  font-size: 15px;
	  font-weight: 700;
	  color: #14112d;
	}
  
	small {
	  font-size: 12px;
	  color: #737688;
	}
  }
  
  .main-chat-body {
	position: relative;
	padding-bottom: 50px;
	padding-bottom: 0;
	max-height: 555px;
	overflow: hidden;
  
	.content-inner {
	  padding: 20px;
	}
  
	.media {
	  + .media {
		margin-top: 20px;
	  }
  
	  &.flex-row-reverse {
		.media-body {
		  margin-left: 0;
		  margin-right: 5px;
		  align-items: flex-end;
		}
  
		.main-msg-wrapper {
		  background-color: $primary;
		  color: $white;
		  margin-right: 15px;
		  border-radius: 4px 0px 4px 4px;
		}
	  }
	}
  
	.media-body {
	  margin-left: 5px;
	  display: flex;
	  flex-direction: column;
	  align-items: flex-start;
  
	  > div span {
		font-size: 11px;
		color: #737688;
		display: flex;
		align-items: center;
	  }
	}
  }
  
  @media (min-width: 576px) {
	.main-chat-body .media.flex-row-reverse .media-body {
	  margin-left: 0;
	}
  }
  
  @media (min-width: 576px) {
	.main-chat-body .media-body {
	  margin-right: 0;
	}
  }
  
  .main-chat-time {
	display: block;
	position: relative;
	text-align: center;
	margin: 20px 0;
  
	&:first-of-type {
	  margin-top: 0;
	}
  
	span {
	  font-size: 10px;
	  font-weight: 500;
	  text-transform: uppercase;
	  letter-spacing: 1px;
	}
  }
  
  .main-msg-wrapper {
	padding: 10px 15px;
	background-color: #eff1f7;
	font-size: 13px;
	margin-bottom: 5px;
	border-radius: 0px;
	display: inline-block;
	position: relative;
	margin-left: 10px;
	border-radius: 0 4px 4px 4px;
  }
  
  .main-chat-footer {
	display: flex;
	align-items: center;
	height: 50px;
	padding: 0 20px;
	border-top: 1px solid #e9edf4;
	background-color: $white;
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: 0 0 5px 5px;
  
	.nav {
	  align-items: center;
	}
  
	.nav-link {
	  padding: 0;
	  color: #737688;
	  font-size: 16px;
	  display: none;
  
	  + .nav-link {
		margin-left: 10px;
	  }
  
	  &:last-child {
		display: block;
		margin-left: 0;
	  }
	}
  
	.form-control {
	  flex: 1;
	  margin: 0 10px;
	  padding-left: 0;
	  padding-right: 0;
	  border-width: 0;
  
	  &:hover, &:focus {
		box-shadow: none;
	  }
	}
  }
  
  @media (min-width: 992px) {
	.main-chat-footer {
	  position: relative;
	  bottom: auto;
	  left: auto;
	  right: auto;
	}
  }
  
  @media (min-width: 576px) {
	.main-chat-footer .nav-link {
	  display: block;
  
	  &:last-child {
		display: none;
	  }
	}
  }
  
  @media (min-width: 576px) {
	.main-chat-footer .form-control {
	  margin-left: 20px;
	}
  }
  
  @media (min-width: 768px) {
	.main-chat-footer .form-control {
	  margin: 0 20px;
	  border:0 !important;
	}
  }
  
  .main-msg-send {
	font-size: 20px;
	color: #4c84ff;
  }
  
  .main-img-user.online {
	position: relative;
  
	&:before {
	  content: '';
	  position: absolute;
	  width: 8px;
	  height: 8px;
	  border-radius: 50%;
	  background: $success;
	  display: block;
	  right: 2px;
	  bottom: 0;
	  z-index: 1;
	}
  }
  
  .main-chat-body .media.flex-row-reverse .main-msg-wrapper:before {
	content: '';
	position: absolute;
	top: 6px;
	border-bottom: 8px solid $primary;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	left: auto;
	right: -12px;
	transform: rotate(90deg);
	display: block;
  }
  
  .main-msg-wrapper:before {
	content: '';
	position: absolute;
	top: 6px;
	border-bottom: 8px solid #eff1f7;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	right: auto;
	left: -12px;
	transform: rotate(-90deg);
	display: block;
  }
  
  .main-chat-body {
	background: url(../images/chat.jpg);
  }