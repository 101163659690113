
.horizontalMenu > .horizontalMenu-list > li {
  > ul.sub-menu {
    opacity: 0;
    visibility: hidden;
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    -o-transition: -o-transform 0.3s, opacity 0.3s;
    -ms-transition: -ms-transform 0.3s, opacity 0.3s;
    -moz-transition: -moz-transform 0.3s, opacity 0.3s;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  }

  &:hover {
    > ul.sub-menu, > .horizontal-megamenu {
      opacity: 1;
      visibility: visible;
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      -ms-transform: rotateX(0deg);
      -o-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
  }

  > {
    ul.sub-menu > li {
      > ul.sub-menu {
        opacity: 0;
        visibility: hidden;
        -webkit-transform-origin: center center;
        -moz-transform-origin: center center;
        -ms-transform-origin: center center;
        -o-transform-origin: center center;
        transform-origin: center center;
        -webkit-transform: translateY(20px);
        -moz-transform: translateY(20px);
        -ms-transform: translateY(20px);
        -o-transform: translateY(20px);
        transform: translateY(20px);
        -o-transition: -o-transform 0.3s, opacity 0.3s;
        -ms-transition: -ms-transform 0.3s, opacity 0.3s;
        -moz-transition: -moz-transform 0.3s, opacity 0.3s;
        -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
      }

      &:hover > ul.sub-menu {
        opacity: 1;
        visibility: visible;
        -webkit-transform: rotateX(0deg);
        -moz-transform: rotateX(0deg);
        -ms-transform: rotateX(0deg);
        -o-transform: rotateX(0deg);
        transform: rotateX(0deg);
      }

      > ul.sub-menu > li {
        > ul.sub-menu {
          opacity: 0;
          visibility: hidden;
          -webkit-transform-origin: center center;
          -moz-transform-origin: center center;
          -ms-transform-origin: center center;
          -o-transform-origin: center center;
          transform-origin: center center;
          -webkit-transform: translateY(20px);
          -moz-transform: translateY(20px);
          -ms-transform: translateY(20px);
          -o-transform: translateY(20px);
          transform: translateY(20px);
          -o-transition: -o-transform 0.3s, opacity 0.3s;
          -ms-transition: -ms-transform 0.3s, opacity 0.3s;
          -moz-transition: -moz-transform 0.3s, opacity 0.3s;
          -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
        }

        &:hover > ul.sub-menu {
          opacity: 1;
          visibility: visible;
          -webkit-transform: rotateX(0deg);
          -moz-transform: rotateX(0deg);
          -ms-transform: rotateX(0deg);
          -o-transform: rotateX(0deg);
          transform: rotateX(0deg);
        }
      }
    }

    .horizontal-megamenu {
      opacity: 0;
      visibility: hidden;
      -webkit-transform-origin: center center;
      -moz-transform-origin: center center;
      -ms-transform-origin: center center;
      -o-transform-origin: center center;
      transform-origin: center center;
      -webkit-transform: translateY(20px);
      -moz-transform: translateY(20px);
      -ms-transform: translateY(20px);
      -o-transform: translateY(20px);
      transform: translateY(20px);
      -o-transition: -o-transform 0.3s, opacity 0.3s;
      -ms-transition: -ms-transform 0.3s, opacity 0.3s;
      -moz-transition: -moz-transform 0.3s, opacity 0.3s;
      -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    }
  }
}
.horizontalMenu {
	html, body, iframe, h1, h2, h3, h4, h5, h6 {
	  margin: 0;
	  padding: 0;
	  border: 0;
	  font: inherit;
	  vertical-align: baseline;
	  font-size: 12px;
	  line-height: 18px;
	}
  
	.cl {
	  clear: both;
	}
  
	img {
	  border: 0 none;
	  max-width: 100%;
	}
  }
  
  object, embed, video {
	border: 0 none;
	max-width: 100%;
  }
  
  .horizontalMenu {
	a:focus {
	  outline: none;
	}
  
	&:before {
	  content: "";
	  display: table;
	}
  
	&:after {
	  content: "";
	  display: table;
	  clear: both;
	}
  }
  
  /* ================== Desktop Main Menu CSS ================== */
  
  .horizontalMenucontainer {
	background-size: cover;
	overflow: hidden;
	background-attachment: fixed;
	background-position: 50% 0;
	background-repeat: no-repeat;
  }
  
  .horizontal-main {
	width: 100%;
	position: fixed;
	background: $white;
	border-bottom: 1px solid #eff0f6;
	border-top: 1px solid #eff0f6;
	z-index: 99;
	box-shadow: 0px 10px 10px 20px rgba(176, 184, 214, 0.09), 10px 10px 15px -5px #b0b8d6;
  }
  
  .horizontal-mainwrapper {
	margin: 0 auto;
  
	/*max-width: 1200px;*/
	width: 100%;
	z-index: 999;
  }
  
  .desktoplogo {
	padding: 1.5rem 0;
	margin: 0;
	float: left;
  
	img {
	  width: 100%;
	}
  }
  
  .horizontalMenu {
	color: $white;
	font-size: 14px;
	padding: 0;
	display: block;
  
	> .horizontalMenu-list {
	  text-align: left;
	  margin: 0 auto 0 auto;
	  width: 100%;
	  display: block;
	  padding: 0;
	}
  }
  
  .mega-menubg {
	border: 1px solid #eff0f6;
	background: $white;
	font-size: 15px;
	padding: 14px 5px;
	box-shadow: 0px 10px 10px 20px rgba(68, 84, 195, 0.05), 10px 10px 15px -5px rgba(68, 84, 195, 0.26);
  }
  
  .horizontalMenu > .horizontalMenu-list > li {
	text-align: center;
	display: block;
	padding: 0;
	margin: 0;
	float: left;
	color: $primary;
  
	/*padding: 1.5rem 0;*/
  
	> a {
	  display: block;
	  padding: 1rem 0.8rem 1rem;
	  text-decoration: none;
	  position: relative;
	  margin: 0;
	  border-left: 1px solid rgb(239, 240, 246);
	}
  
	&:first-child > a {
	  margin-left: 0;
	}
  
	&:last-child > a {
	  border-right: 1px solid rgb(239, 240, 246);
	}
  
	a.menuhomeicon {
	  padding-left: 25px;
	  padding-right: 25px;
	}
  
	> a {
	  i {
		display: inline-block;
		font-size: 16px;
		line-height: inherit;
		margin-right: 10px;
		vertical-align: top;
	  }
  
	  .fa.fa-home {
		font-size: 15px;
	  }
  
	  .arrow:after {
		border-left: 4px solid rgba(0, 0, 0, 0);
		border-right: 4px solid rgba(0, 0, 0, 0);
		border-top: 4px solid;
		content: "";
		float: right;
		right: 11px;
		height: 0;
		margin: 0 0 0 14px;
		position: absolute;
		text-align: right;
		top: 33px;
		width: 0;
	  }
	}
  
	&.rightmenu {
	  float: right;
  
	  a {
		padding: 0px 30px 0px 20px;
		border-right: none;
  
		.i {
		  font-size: 15px;
		}
	  }
  
	  float: right;
  
	  &:before {
		content: "";
		display: table;
	  }
	}
  }
  
  /* Desktop Search Bar */
  
  .horizontalMenu-list > li.rightmenu:after {
	content: "";
	display: table;
  }
  
  .horizontalMenu > .horizontalMenu-list > li {
	&.rightmenu {
	  &:after {
		clear: both;
	  }
  
	  > .topmenusearch {
		float: right;
		width: 210px;
		height: 39px;
		position: relative;
		margin: 16px 0px 0px 0px;
  
		.searchicon {
		  -webkit-transition: all 0.7s ease 0s;
		  -moz-transition: all 0.7s ease 0s;
		  -o-transition: all 0.7s ease 0s;
		  transition: all 0.7s ease 0s;
		}
  
		input {
		  width: 100%;
		  position: relative;
		  float: right;
		  top: 0;
		  right: 0;
		  bottom: 0;
		  width: 100%;
		  border: 0;
		  padding: 0;
		  margin: 0;
		  text-indent: 15px;
		  height: 39px;
		  z-index: 2;
		  outline: none;
		  color: #333;
		  background-color: #efefef;
		  -webkit-transition: all 0.7s ease 0s;
		  -moz-transition: all 0.7s ease 0s;
		  -o-transition: all 0.7s ease 0s;
		  transition: all 0.7s ease 0s;
		  font-size: 12px;
  
		  &::placeholder {
			color: #b3bdca;
		  }
  
		  &:focus {
			color: #333;
			width: 230px;
  
			~ {
			  .btnstyle .fa {
				color: #6b6f80;
				opacity: 1;
			  }
  
			  .searchicon {
				opacity: 1;
				z-index: 3;
				color: #FFFFFF;
			  }
			}
		  }
		}
  
		.btnstyle {
		  top: 0px;
		  position: absolute;
		  right: 0;
		  bottom: 0;
		  width: 40px;
		  line-height: 30px;
		  z-index: 1;
		  cursor: pointer;
		  opacity: 0.3;
		  color: #333;
		  z-index: 1000;
		  background-color: transparent;
		  border: solid 0px;
		  -webkit-transition: all 0.7s ease 0s;
		  -moz-transition: all 0.7s ease 0s;
		  -o-transition: all 0.7s ease 0s;
		  transition: all 0.7s ease 0s;
  
		  .fa {
			line-height: 37px;
			margin: 0;
			padding: 0;
			text-align: center;
		  }
  
		  &:hover .fa {
			opacity: 1;
		  }
		}
	  }
  
	  zoom: 1;
	}
  
	> {
	  ul.sub-menu {
		position: absolute;
		top: 56px;
		z-index: 1;
		margin: 0px;
		padding: 5px;
		min-width: 190px;
		background-color: $white;
  
		border:1px solid #eff0f6;
		box-shadow: 0px 10px 10px 20px rgba(68, 84, 195, 0.05), 10px 10px 15px -5px rgba(68, 84, 195, 0.26);
  
		> li {
		  position: relative;
		  margin: 0px;
		  padding: 0px;
		  display: block;
  
		  > {
			a {
			  background-image: none;
			  color: $color;
			  border-right: 0 none;
			  text-align: left;
			  display: block;
			  line-height: 22px;
			  padding: 8px 12px;
			  text-transform: none;
			  font-size: 13px;
			  letter-spacing: normal;
			  border-right: 0px solid;
  
			  &:hover {
				text-decoration: none;
				color: $primary;
			  }
  
			  > .i {
				margin-right: 9px;
			  }
			}
  
			ul.sub-menu {
			  min-width: 220px;
			  position: absolute;
			  left: 103%;
			  top: 0;
			  margin: 0;
			  padding: 2px;
			  list-style: none;
			  background-color: $white;
			  border: 1px solid #eff0f6;
  
			  > li {
				position: relative;
				margin: 0px;
				padding: 1px;
				display: block;
  
				> {
				  a {
					background-image: none;
					color: #6b6f80;
					border-right: 0 none;
					text-align: left;
					display: block;
					line-height: 22px;
					padding: 8px 12px;
					text-transform: none;
					font-size: 13px;
					letter-spacing: normal;
					border-right: 0px solid;
  
					&:hover {
					  text-decoration: none;
					}
  
					> .i {
					  margin-right: 9px;
					}
				  }
  
				  ul.sub-menu {
					min-width: 220px;
					position: absolute;
					left: 100%;
					top: 0;
					margin: 0px;
					list-style: none;
					padding: 0px;
					background-color: $white;
					border: 1px solid #eff0f6;
  
					> li {
					  position: relative;
					  margin: 0px;
					  padding: 0px;
					  display: block;
  
					  > a {
						background-image: none;
						color: #6b6f80;
						border-right: 0 none;
						text-align: left;
						display: block;
						line-height: 22px;
						padding: 8px 12px;
						text-transform: none;
						font-size: 13px;
						letter-spacing: normal;
						border-right: 0px solid;
  
						&:hover {
						  text-decoration: none;
						}
  
						> .i {
						  margin-right: 9px;
						}
					  }
					}
				  }
				}
			  }
			}
		  }
		}
	  }
  
	  a {
		&.active {
		  color: $primary;
		  text-decoration: none;
		  background: #f4f5fc;
  
		  .hor-icon {
			color: $primary;
			fill: rgba(68, 84, 195, 0.3);
		  }
		}
  
		&:hover {
		  color: $primary;
		  text-decoration: none;
  
		  .hor-icon {
			color: $primary;
			fill: rgba(68, 84, 195, 0.3);
		  }
		}
	  }
  
	  ul.sub-menu {
		.sub-menu-sub:after {
		  content: '\e92f';
		  font-family: 'feather';
		  font-size: 13px;
		  position: absolute;
		  top: 11px;
		  right: 14px;
		  color: #70768e;
		}
  
		> li > {
		  a:hover, ul.sub-menu > li > a:hover {
			text-decoration: none;
			color: $primary;
		  }
		}
	  }
  
	  .horizontal-megamenu {
		.link-list li a:hover {
		  text-decoration: none;
		  color: $primary;
		}
  
		width: 100%;
		left: 0px;
		position: absolute;
		top: 56px;
		z-index: 1;
		margin: 0px;
		text-align: left;
		font-size: 15px;
  
		.megamenu-content {
		  border: 1px solid #eff0f6;
		  background-color: $white;
		  padding: 14px 20px;
		}
  
		.title {
		  padding: 5px 5px 5px 0px;
		  font-size: 14px;
		  color: #495057;
		  margin: 0px 0px 4px 0px;
		  text-align: left;
		  font-weight: 600;
		}
  
		.wsmwnutxt {
		  width: 100%;
		  font-size: 14px;
		  text-align: justify;
		  line-height: 22px;
		  color: #424242;
		  margin-top: 10px;
		}
  
		.link-list li {
		  display: block;
		  text-align: center;
		  white-space: nowrap;
		  text-align: left;
		  padding: 5px 10px 5px 25px;
  
		  a {
			line-height: 18px;
			border-right: none;
			text-align: left;
			padding: 6px 0px;
  
			/*background: rgb(255,255,255,0.04);*/
			background-image: none;
			color: $color;
			border-right: 0 none;
			display: block;
			font-size: 13px;
		  }
		}
  
		li {
		  .i {
			margin-right: 5px;
			text-align: center;
			width: 18px;
		  }
  
		  a:hover {
			background: transparent;
			text-decoration: none;
		  }
		}
  
		.link-list li .i {
		  font-size: 12px;
		}
  
		li .i {
		  margin-right: 5px;
		  text-align: center;
		  width: 18px;
		}
  
		.mrgtop {
		  margin-top: 15px;
		}
  
		.show-grid div {
		  padding-bottom: 10px;
		  padding-top: 10px;
		  background-color: #dbdbdb;
		  border: 1px solid #e7e7e7;
		  color: #6a6a6a;
		  margin: 2px 0px;
		  font-size: 13px;
		}
  
		&.halfmenu {
		  width: 30%;
		  right: auto;
		  left: auto;
		}
  
		&.halfdiv {
		  width: 20%;
		  right: auto;
		  left: auto;
		}
  
		.menu_form {
		  width: 100%;
		  display: block;
  
		  input[type="text"] {
			width: 100%;
			border: 1px solid #e2e2e2;
			color: #6b6f80;
			font-size: 13px;
			padding: 8px 5px;
			margin-bottom: 8px;
		  }
  
		  textarea {
			width: 100%;
			border: 1px solid #e2e2e2;
			color: #6b6f80;
			font-size: 13px;
			padding: 8px 5px;
			margin-bottom: 8px;
			min-height: 122px;
		  }
  
		  input {
			&[type="submit"] {
			  width: 25%;
			  display: block;
			  height: 32px;
			  float: right;
			  border: none;
			  margin-right: 15px;
			  cursor: pointer;
			  background-color: #e1e1e1;
			  -webkit-border-radius: 2px;
			  -moz-border-radius: 2px;
			  border-radius: 2px;
			}
  
			&[type="button"] {
			  width: 25%;
			  display: block;
			  height: 32px;
			  float: right;
			  border: none;
			  cursor: pointer;
			  background-color: #e1e1e1;
			  -webkit-border-radius: 2px;
			  -moz-border-radius: 2px;
			  border-radius: 2px;
			}
		  }
		}
  
		.carousel-inner .item img {
		  width: 100%;
		}
  
		.carousel-control-next, .carousel-control-prev {
		  opacity: 0.8;
		}
  
		.carousel-caption {
		  bottom: 0px;
		  background-color: $black-7;
		  font-size: 13px;
		  height: 31px;
		  left: 0;
		  padding: 7px 0;
		  right: 0;
		  width: 100%;
		}
  
		.typography-text {
		  padding: 0px 0px;
		  font-size: 14px;
  
		  ul {
			padding: 0px 0px;
			margin: 0px;
		  }
  
		  p {
			text-align: justify;
			line-height: 24px;
			color: #656565;
		  }
  
		  ul li {
			display: block;
			padding: 2px 0px;
			line-height: 22px;
  
			a {
			  color: #656565;
			}
		  }
		}
	  }
	}
  }
  
  /* ================== Desktop Drop Down Menu CSS ================== */
  
  /* ================== Desktop Mega Menus CSS  ================== */
  
  @media (min-width: 1024px) {
	.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list:first-child {
	  border: 0;
	}
  }
  
  /*= Desktop Half Menu CSS =*/
  
  /*= Desktop HTML Form Menu CSS =*/
  
  /* ================== Desktop Extra CSS ================== */
  
  .horizontal-header {
	display: none;
  }
  
  .overlapblackbg {
	opacity: 0;
	visibility: hidden;
  }
  
  .horizontalMenu {
	.horizontalMenu-click, .horizontalMenu-click02 {
	  display: none;
	}
  }
  
  .hometext {
	display: none;
  }
  
  /*==============================================================================
								Start Mobile CSS
  ===============================================================================*/
  
  /* ================== Mobile Menu Change Brake Point ================== */
  
  @media only screen and (max-width: 991px) {
	/* ================== Mobile Base CSS ================== */
  
	body.active {
	  overflow: hidden;
	}
  
	/* ================== Mobile Main Menu CSS ================== */
  
	.desktoplogo {
	  display: none;
	}
  
	.horizontal-main {
	  height: 0;
	  padding: 0;
	}
  
	.horizontalMenucontainer {
	  background-attachment: local;
	  background-position: 33% 0%;
	}
  
	.horizontalMenu {
	  width: 100%;
	  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	  left: 0;
	  overflow-y: hidden;
	  padding: 0;
	  top: 0;
	  position: fixed;
	  visibility: hidden;
	  margin: 0px;
  
	  > .horizontalMenu-list {
		height: auto;
		min-height: 100%;
		width: 240px;
		padding-bottom: 0;
		margin-left: -240px;
		display: block;
		text-align: center;
  
		> li {
		  width: 100%;
		  display: block;
		  float: none;
		  border-right: none;
		  background-color: transparent;
		  position: relative;
		  white-space: inherit;
		  clear: right;
		  padding: 0;
		  border-bottom: 1px solid #eff0f6;
  
		  > a {
			padding: 12px 32px 12px 17px;
			font-size: 14px;
			text-align: left;
			border-right: solid 0px;
			line-height: 25px;
			border-bottom: 0px solid;
			border-bottom-color: rgba(255, 255, 255, 0.13);
			position: static;
  
			span.fa.fa-caret-down {
			  display: none;
			}
		  }
  
		  a.menuhomeicon {
			padding-left: 17px;
			padding-right: 17px;
			border-top: solid 1px rgba(255, 255, 255, 0.13);
		  }
  
		  > a {
			> .fa {
			  font-size: 16px;
			  color: $black;
			}
  
			.arrow:after {
			  display: none;
			}
		  }
  
		  &:hover > a {
			background-color: rgba(0, 0, 0, 0.08);
			text-decoration: none;
		  }
  
		  > {
			a > .hometext {
			  display: inline-block;
			}
  
			ul.sub-menu {
			  display: none;
			  position: relative;
			  top: 0px;
			  background-color: rgba(255, 255, 255, 0.04);
			  border: none;
			  padding: 0 0 0 25px;
			  opacity: 1;
			  visibility: visible;
			  -webkit-transform: none;
			  -moz-transform: none;
			  -ms-transform: none;
			  -o-transform: none;
			  transform: none;
			  -webkit-transition: inherit;
			  -moz-transition: inherit;
			  transition: inherit;
			  -webkit-transform-style: flat;
  
			  > li {
				> a {
				  line-height: 20px;
				  font-size: 13px;
				  padding: 10px 0px 10px 16px;
				}
  
				span + a {
				  padding-right: 30px;
				}
  
				> a {
				  > .fa {
					display: none;
				  }
  
				  &:hover {
					background-color: #e7e7e7;
					color: #6b6f80;
					text-decoration: underline;
				  }
				}
			  }
  
			  li:hover > a {
				background-color: #e7e7e7;
				color: #6b6f80;
			  }
  
			  > li > ul.sub-menu {
				min-width: 100%;
				position: static;
				left: 100%;
				top: 0;
				display: none;
				margin: 0px;
				padding: 0px;
				border: solid 0px;
				transform: none;
				opacity: 1;
				visibility: visible;
				-webkit-transform: none;
				-moz-transform: none;
				-ms-transform: none;
				-o-transform: none;
				transform: none;
				-webkit-transition: inherit;
				-moz-transition: inherit;
				transition: inherit;
				-webkit-transform-style: flat;
  
				> li {
				  margin: 0px 0px 0px 0px;
				  padding: 0px;
				  position: relative;
  
				  > a {
					line-height: 20px;
					font-size: 13px;
					padding: 10px 0px 10px 26px;
				  }
  
				  span + a {
					padding-right: 30px;
				  }
  
				  > a {
					> .fa {
					  display: none;
					}
  
					&:hover {
					  background-color: #e7e7e7;
					  color: #6b6f80;
					  text-decoration: underline;
					}
  
					&.active {
					  color: #6b6f80;
					}
				  }
  
				  &:hover > a {
					color: #6b6f80;
				  }
  
				  > ul.sub-menu {
					width: 100%;
					position: static;
					left: 100%;
					top: 0;
					display: none;
					margin: 0px;
					padding: 0px;
					border: solid 0px;
					transform: none;
					opacity: 1;
					visibility: visible;
					-webkit-transform: none;
					-moz-transform: none;
					-ms-transform: none;
					-o-transform: none;
					transform: none;
					-webkit-transition: inherit;
					-moz-transition: inherit;
					transition: inherit;
					-webkit-transform-style: flat;
  
					> li {
					  margin: 0px 0px 0px 0px;
  
					  > a {
						line-height: 20px;
						font-size: 13px;
						padding: 10px 0px 10px 34px;
						color: #6b6f80;
					  }
  
					  span + a {
						padding-right: 30px;
					  }
  
					  > a {
						> .fa {
						  display: none;
						}
  
						&:hover {
						  background-color: #e7e7e7;
						  color: #6b6f80;
						  text-decoration: underline;
						}
  
						&.active {
						  color: #6b6f80;
						}
					  }
					}
				  }
				}
			  }
			}
  
			.horizontal-megamenu {
			  color: #6b6f80;
			  display: none;
			  position: relative;
			  top: 0px;
			  padding: 10px 0px;
			  border: solid 0px;
			  transform: none;
			  opacity: 1;
			  visibility: visible;
			  -webkit-transform: none;
			  -moz-transform: none;
			  -ms-transform: none;
			  -o-transform: none;
			  transform: none;
			  -webkit-transition: inherit;
			  -moz-transition: inherit;
			  transition: inherit;
			  border-bottom: 1px solid rgba(255, 255, 255, 0.13);
			  -webkit-transform-style: flat;
  
			  &.halfmenu {
				width: 100%;
				margin: 0px;
				padding: 5px 0px 10px 0px;
			  }
  
			  .title {
				color: #6b6f80;
				font-size: 15px;
				padding: 10px 8px 10px 0px;
			  }
  
			  > ul {
				width: 100%;
				margin: 0px;
				padding: 0px;
				font-size: 13px;
  
				> li > a {
				  padding: 9px 14px;
				  line-height: normal;
				  font-size: 13px;
				  background-color: #e7e7e7;
				  color: #6b6f80;
  
				  &:hover {
					background-color: #6b6f80;
				  }
				}
			  }
  
			  ul li.title {
				line-height: 26px;
				color: #6b6f80;
				margin: 0px;
				font-size: 15px;
				padding: 7px 15px;
				background-color: transparent;
			  }
  
			  &.halfdiv {
				width: 100%;
			  }
  
			  .menu_form {
				padding: 5px 0px 62px 0px;
			  }
  
			  .show-grid div {
				margin: 0px;
			  }
  
			  .menu_form {
				input {
				  &[type="button"], &[type="submit"] {
					width: 46%;
				  }
				}
  
				textarea {
				  min-height: 100px;
				}
			  }
			}
		  }
		}
	  }
	}
  
	/* ================== Mobile Slide Down Links CSS ================== */
  
	/* ================== Mobile Mega Menus CSS  ================== */
  
	/* ================== Mobile Header CSS ================== */
  
	.horizontal-header {
	  width: 100%;
	  display: block;
	  position: relative;
	  top: 0;
	  left: 0;
	  z-index: 1;
	  height: 54px;
	  background-color: #eaecf0;
	  text-align: center;
	  -webkit-transition: all 0.25s ease-in-out;
	  -moz-transition: all 0.25s ease-in-out;
	  -o-transition: all 0.25s ease-in-out;
	  -ms-transition: all 0.25s ease-in-out;
	  transition: all 0.25s ease-in-out;
	  box-shadow: 0 0 1px $black-3;
	}
  
	.active .horizontal-header {
	  -webkit-transition: all 0.25s ease-in-out;
	  -moz-transition: all 0.25s ease-in-out;
	  -o-transition: all 0.25s ease-in-out;
	  -ms-transition: all 0.25s ease-in-out;
	  transition: all 0.25s ease-in-out;
	}
  
	.horizontal-header .smllogo {
	  display: inline-block;
	  margin-top: 15px;
	}
  
	/* Mobile Search Bar*/
  
	.horizontalMenu > .horizontalMenu-list > li.rightmenu > .topmenusearch {
	  width: 86%;
	  margin: 7% 7%;
	  padding: 0px;
  
	  input {
		border-radius: 50px;
  
		&:focus {
		  width: 100%;
		}
	  }
	}
  
	.callusbtn {
	  color: #6b6f80;
	  font-size: 21px;
	  position: absolute;
	  right: 5px;
	  top: 0px;
	  transition: all 0.4s ease-in-out 0s;
	  z-index: 102;
	  padding: 11px 14px;
  
	  .fa {
		vertical-align: top;
		margin-top: 4px;
	  }
  
	  &:hover .fa {
		color: #6b6f80;
	  }
	}
  
	/* Mobile Toggle Menu icon (X ICON) */
  
	.animated-arrow {
	  position: absolute;
	  top: -2px;
	  z-index: 102;
	  -webkit-transition: all 0.4s ease-in-out;
	  -moz-transition: all 0.4s ease-in-out;
	  -o-transition: all 0.4s ease-in-out;
	  -ms-transition: all 0.4s ease-in-out;
	  transition: all 0.4s ease-in-out;
	  cursor: pointer;
	  padding: 16px 35px 16px 0px;
	  margin: 15px 0 0 15px;
  
	  span {
		cursor: pointer;
		height: 2px;
		width: 17px;
		background: $primary;
		position: absolute;
		display: block;
		content: '';
  
		&:before, &:after {
		  cursor: pointer;
		  height: 2px;
		  width: 17px;
		  background: $primary;
		  position: absolute;
		  display: block;
		  content: '';
		}
  
		&:before {
		  top: -7px;
		  width: 23px;
		}
  
		&:after {
		  bottom: -7px;
		  width: 17px;
		}
  
		transition: all 500ms ease-in-out;
  
		&:before, &:after {
		  transition: all 500ms ease-in-out;
		}
	  }
	}
  
	.active .animated-arrow {
	  span {
		&:after {
		  width: 23px;
		  top: -3px;
		}
  
		background-color: transparent;
  
		&:before {
		  top: -3px;
		}
	  }
  
	  &.active span:after {
		top: -3px;
	  }
  
	  span {
		&:before {
		  transform: rotate(45deg);
		  -moz-transform: rotate(45deg);
		  -ms-transform: rotate(45deg);
		  -o-transform: rotate(45deg);
		  -webkit-transform: rotate(45deg);
		  bottom: 0px;
		}
  
		&:after {
		  transform: rotate(-45deg);
		  -moz-transform: rotate(-45deg);
		  -ms-transform: rotate(-45deg);
		  -o-transform: rotate(-45deg);
		  -webkit-transform: rotate(-45deg);
		}
	  }
	}
  
	/* ================== Mobile Overlay/Drawer CSS ================== */
  
	.overlapblackbg {
	  right: 0;
	  width: calc(100% - 240px);
	  height: 100vh;
	  min-height: 100%;
	  position: fixed;
	  top: 0;
	  opacity: 0;
	  visibility: hidden;
	  background-color: $black-4;
	  cursor: pointer;
	}
  
	.active .horizontalMenu .overlapblackbg {
	  opacity: 1;
	  visibility: visible;
	  -webkit-transition: opacity 1.5s ease-in-out;
	  -moz-transition: opacity 1.5s ease-in-out;
	  -ms-transition: opacity 1.5s ease-in-out;
	  -o-transition: opacity 1.5s ease-in-out;
	}
  
	.horizontalMenucontainer {
	  -webkit-transition: all 0.25s ease-in-out;
	  -moz-transition: all 0.25s ease-in-out;
	  -o-transition: all 0.25s ease-in-out;
	  -ms-transition: all 0.25s ease-in-out;
	  transition: all 0.25s ease-in-out;
	}
  
	.active {
	  .horizontalMenucontainer {
		margin-left: 240px;
		-webkit-transition: all 0.25s ease-in-out;
		-moz-transition: all 0.25s ease-in-out;
		-o-transition: all 0.25s ease-in-out;
		-ms-transition: all 0.25s ease-in-out;
		transition: all 0.25s ease-in-out;
	  }
  
	  .horizontalMenu {
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		visibility: visible;
		z-index: 1000;
		top: 0;
		height: 100% !important;
  
		> .horizontalMenu-list {
		  -webkit-transition: all 0.25s ease-in-out;
		  -moz-transition: all 0.25s ease-in-out;
		  -o-transition: all 0.25s ease-in-out;
		  -ms-transition: all 0.25s ease-in-out;
		  transition: all 0.25s ease-in-out;
		  margin-left: 0;
		  border-right: 1px solid #eff0f6;
		}
	  }
	}
  
	/* ================== Mobile Sub Menu Expander Arrows  ================== */
  
	.horizontalMenu > .horizontalMenu-list > li {
	  > .horizontalMenu-click {
		height: 49px;
		position: absolute;
		top: 0;
		right: 0;
		display: block;
		cursor: pointer;
		width: 100%;
	  }
  
	  .horizontalMenu-click.ws-activearrow > i {
		transform: rotate(180deg);
	  }
  
	  > {
		.horizontalMenu-click > i {
		  display: block;
		  height: 23px;
		  width: 25px;
		  margin-top: 14px;
		  margin-right: 10px;
		  background-size: 25px;
		  font-size: 21px;
		  color: $black-2;
		  float: right;
		}
  
		ul.sub-menu > li .horizontalMenu-click02 {
		  height: 41px;
		  position: absolute;
		  top: 0;
		  right: 0;
		  display: block;
		  cursor: pointer;
		  width: 100%;
  
		  > i {
			display: block;
			height: 23px;
			width: 25px;
			margin: 8px 6px 8px 6px;
			background-size: 25px;
			font-size: 21px;
			color: $black-2;
			float: right;
  
			&.horizontalMenu-rotate {
			  -webkit-transform: rotate(180deg);
			  -moz-transform: rotate(180deg);
			  -ms-transform: rotate(180deg);
			  -o-transform: rotate(180deg);
			  transform: rotate(180deg);
			}
		  }
		}
	  }
	}
  
	.mega-menubg {
	  box-shadow: none !important;
	  border: 0 !important;
	  padding: 0 !important;
	  background: $white-2 !important;
	}
  
	.horizontalMenu > .horizontalMenu-list > li {
	  > {
		a {
		  &.sub-icon:before, &:hover.sub-icon:before {
			display: none;
		  }
		}
  
		ul.sub-menu {
		  .sub-menu-sub:after {
			display: none;
		  }
  
		  > li > a:hover {
			background: rgba(227, 212, 255, 0.2);
			color: $primary;
			text-decoration: none;
		  }
  
		  li:hover > a {
			background: $white;
			color: $primary !important;
		  }
		}
	  }
  
	  &:hover > a {
		background: $white;
		color: $primary !important;
		text-decoration: none;
	  }
  
	  > ul.sub-menu > li {
		&:hover > a {
		  color: $primary;
		  text-decoration: none;
		}
  
		> ul.sub-menu {
		  box-shadow: none !important;
		  background: rgba(0, 0, 0, 0.02);
		  border-radius: 0px !important;
		}
	  }
	}
  
	.mega-menubg {
	  &:before {
		display: none;
	  }
  
	  ul {
		padding: 0;
	  }
	}
  
	.horizontalMenu > .horizontalMenu-list > li {
	  > {
		ul.sub-menu {
		  box-shadow: none;
		  border-radius: 0 !important;
  
		  &:before {
			display: none;
		  }
		}
  
		a.active > .fa {
		  color: $primary !important;
		}
	  }
  
	  &:hover > a > .fa {
		color: $primary !important;
	  }
  
	  > a {
		> .horizontal-icon {
		  display: none;
		}
  
		margin: 0;
	  }
  
	  &:last-child > a {
		border-right: 0px !important;
	  }
	}
  
	/*End Media Query*/
  }
  
  /* Extra @Media Query*/
  
  @media (min-width: 993px) and (max-width: 1024px) {
	.horizontalMenu > .horizontalMenu-list > li {
	  font-size: 10px;
  
	  > a .fa {
		margin-right: 8px;
		font-size: 13px;
	  }
	}
  }
  
  @media (min-width: 1260px) and (max-width: 1366px) {
	.horizontalMenu {
	  width: 100%;
	}
  
	.hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
	  padding: 1rem 1.64rem 1rem !important;
	}
  }
  @media (min-width: 1161px) and (max-width: 1259px) {
	.horizontalMenu {
	  width: 100%;
	}
  
	.hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
	  padding: 1rem 0.75rem 1rem !important;
	}
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1162px) {
	.desktoplogo {
	  margin-left: 12px;
	}
	.horizontal-main{
		height:57px;
		z-index:999;
	}
	.horizontalMenu > .horizontalMenu-list > li > a {
	  > .arrow {
		display: none;
	  }
  
	  padding-left: 12px;
	  padding-right: 12px;
	}
  
	.hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
	  padding: 1rem 0.75rem 1rem !important;
	}
  
	.horizontalMenu > .horizontalMenu-list > li a.menuhomeicon {
	  padding-left: 22px;
	  padding-right: 22px;
	}
  }
  
  .horizontalMenucontainer .app-header {
	background: $white;
	padding-top: 16px !important;
	padding-bottom: 16px !important;
	height: 80px;
  }
  
  .horizontalMenu > .horizontalMenu-list > li > a .horizontal-icon {
	margin-right: 0;
  }
  
  .hor-menu {
	.horizontalMenu > .horizontalMenu-list > li > a {
	  padding: 1rem 1.5rem 1rem;
	}
  
	&.horizontal-main {
	  margin-top: 80px;
	}
  }
  .card-shadow{
	.horizontal-main {
	  margin-top: 80px;
	}
	}
  
  .sub-menu li a:before, .mega-menubg li a:before {
	content: '\e048';
	margin-right: 8px;
	position: relative;
	font-family: 'typicons' !important;
	opacity: 0.6;
  }
  
  .hor-icon {
	color: #8e98db;
	fill: #d2d6f1;
	font-size: 25px;
	line-height: 30px;
	text-align: center;
	vertical-align: middle;
	margin-right: 8px;
	margin-left: -8px;
	margin-top: -2px;
  }
  
  .header .dropdown-menu.show {
	z-index: 1;
  }
  
  @media (max-width: 1200px) {
	.analytics-img.w-90 {
	  width: 50% !important;
	  margin: 0 auto;
	  text-align: center !important;
	  display: block;
	}
  }
  
  @media (min-width: 992px) {
	.horizontalMenucontainer .header.top-header {
	  box-shadow: none ;
	}
  }
  
  .horizontalMenu > .horizontalMenu-list > li:hover a .hor-icon {
	color: $primary;
  }
  